import React from "react";
import styled from "styled-components";
// import DefaultSpan from "../common/DefaultSpan";
import DefaultText from "../common/DefaultText";
import DefaultButton from "../common/DefaultButton";

import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import AutoGraphRoundedIcon from "@mui/icons-material/AutoGraphRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import RouteRoundedIcon from "@mui/icons-material/RouteRounded";
import PlayLessonRoundedIcon from "@mui/icons-material/PlayLessonRounded";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import VideoLibraryRoundedIcon from "@mui/icons-material/VideoLibraryRounded";
import SportsScoreRoundedIcon from "@mui/icons-material/SportsScoreRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import EventRoundedIcon from "@mui/icons-material/EventRounded";
import UnderlinedText from "../common/UnderlinedText";

const listItems = [
  {
    icon: <TuneRoundedIcon />,
    text: "Plataforma customizable",
  },
  {
    icon: <AutoGraphRoundedIcon />,
    text: "Analítica y reporting 24/7",
  },
  {
    icon: <GroupsRoundedIcon />,
    text: "Creación de grupos",
  },
  {
    icon: <SupportAgentRoundedIcon />,
    text: "Customer Support",
  },
  {
    icon: <RouteRoundedIcon />,
    text: "+10 Rutas de formación",
  },
  {
    icon: <PlayLessonRoundedIcon />,
    text: "Cursos de expertos ADEN",
  },
  {
    icon: <DashboardCustomizeRoundedIcon />,
    text: "Creación de cursos propios",
  },
  {
    icon: <WorkspacePremiumRoundedIcon />,
    text: "Certificados virtuales",
  },
  {
    icon: <VideoLibraryRoundedIcon />,
    text: "Microlearnings",
  },
  {
    icon: <SportsScoreRoundedIcon />,
    text: "Desafíos",
  },
  {
    icon: <Diversity3RoundedIcon />,
    text: "Networking",
  },
  {
    icon: <EventRoundedIcon />,
    text: "Eventos",
  },
];

const IntegralSolution = (props) => {
  const { handleOpen } = props;

  return (
    <IntegralSolutionWrapper>
      <Left>
        <DefaultText
          id="fontTitle"
          color="#13003E"
          fontWeight="700"
          fontSize="48px"
        >
          ¿Qué es
          {" "}
          <UnderlinedText
            textDecorationColor="#BF80FF"
            textDecorationStyle="solid"
            heigt="9px"
            color="#13003E"
          >
            AcrópolisLAB
          </UnderlinedText>{" "}
          
          {/* <DefaultSpan color="#B31D15">AcrópolisLAB</DefaultSpan>? */}
        </DefaultText>
        <DefaultText color="#616161" fontWeight="400" fontSize="18px">
          AcrópolisLAB es una solución integral para tu empresa a través de una
          plataforma de aprendizaje única, con contenido desarrollado por
          expertos y acciones que apuntan al crecimiento profesional.
        </DefaultText>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="primary"
            variant="contained"
          >
            Empezar ahora
          </DefaultButton>
        </Buttons>
      </Left>
      <Rigth>
        <List>
          {listItems.map((l) => (
            <Item>
              <Icon>{l.icon}</Icon>
              <DefaultText fontSize="0.8rem">{l.text}</DefaultText>
            </Item>
          ))}
        </List>
      </Rigth>
    </IntegralSolutionWrapper>
  );
};

export default IntegralSolution;

const IntegralSolutionWrapper = styled.section`
  height: auto;
  width: calc(100% - 200px);
  padding: 0 100px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 5% 0;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 12px;
    width: calc(100% - 24px);
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: left;
  padding: inherit;
  @media (max-width: 2260px) {
    padding: 3rem;
  }
  @media (max-width: 1440px) {
    padding: 1rem;
    width: 100%;
  }
`;

const Rigth = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
`;

const List = styled.div`
  width: calc(100% - 1rem);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 0.5rem;
  margin-top: 1rem;
`;

const Item = styled.div`
  margin: 2px 0;
  //box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.8rem;
  gap: 1rem;
  //width: calc(140px + 2rem);
  //height: 140px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 15px;

  p,
  b {
    color: #09001F;
  }
  svg {
    fill: #BF80FF;
  }
`;

const Buttons = styled.div`
  width: 100%;
  margin-top: 18px;
`;

const Icon = styled.div`
  text-align: center;
`;
