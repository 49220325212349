import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DefaultButton from "../../DefaultButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Snackbar } from "@mui/material";
import PhoneField from "../../PhoneInput";
import useCountries from "../../../../hooks/useCountries";
import SelectField from "../../SelectField";
import { position } from "../../../../utils/form";

const TOTAL_STEP = 5;
const SIZE = [
  {
    value: 1,
    name: "1 - 5",
  },
  {
    value: 2,
    name: "6 - 10",
  },
  {
    value: 3,
    name: "11 - 50",
  },
  {
    value: 4,
    name: "51 - 100",
  },
  {
    value: 5,
    name: "101 - 500",
  },
  {
    value: 6,
    name: "+500",
  },
];

const REGEX_EMAIL = /^[-\w.%+]{1,64}@(?:[aden]{4}\.){1,125}[A-Z]{2,63}$/i;
// const REGEX_EMAIL = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

const FormCases = (props) => {
  const { step, handleStep, handleForm, submitData } = props;

  const [form, setForm] = useState({});
  const [valid, setValid] = useState(false);
  const [selectSize, setSelectSize] = useState(false);
  const [openSkackbar, setOpenSnackbar] = useState(false);

  const { countries, regions, handleRegion } = useCountries();

  useEffect(() => {
    validateForm(form);
  }, [step, selectSize]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((state) => ({ ...state, [name]: value }));
    if (name === "country") {
      handleRegion(value);
      validateForm({ ...form, region: null });
      return;
    }
    validateForm({ ...form, [name]: value });
  };

  const validateForm = (form) => {
    if (!!form?.name && !!form?.lastName && step === 0) setValid(true);
    else if (
      !!form?.name &&
      !!form?.lastName &&
      !!form?.country &&
      !!form?.region &&
      step === 1
    )
      setValid(true);
    else if (
      !!form?.name &&
      !!form?.lastName &&
      !!form?.country &&
      !!form?.region &&
      !!form?.phone &&
      !!form?.email &&
      REGEX_EMAIL.test(form?.email) &&
      step === 2
    )
      setValid(true);
    else if (
      !!form?.name &&
      !!form?.lastName &&
      !!form?.country &&
      !!form?.region &&
      !!form?.phone &&
      !!form?.email &&
      !!form?.business &&
      !!form?.position &&
      !!selectSize &&
      step === 3
    )
      setValid(true);
    else {
      if (!REGEX_EMAIL.test(form?.email) && step === 2) {
        console.log("email incorrecto!");
      }
      setValid(false);
    }
  };

  const handleNext = () => {
    setValid(false);
    handleStep(step + 1, TOTAL_STEP);
    handleForm(form);
  };

  const handleBack = () => {
    setValid(false);
    handleStep(step - 1, TOTAL_STEP);
  };

  const handleSubmit = () => {
    const body = {
      ...form,
      message: form?.message || "",
      size: selectSize,
    };
    submitData(body);
  };

  return (
    <FormWrapper>
      {/* Form1 - Datos personales */}
      {step === 0 && (
        <>
          <TextField
            required
            value={form?.name}
            onChange={handleChange}
            name="name"
            label="Nombre"
            placeholder="Escribe tu nombre"
          />
          <TextField
            required
            value={form?.lastName}
            onChange={handleChange}
            name="lastName"
            label="Apellido"
            placeholder="Escribe tu apellido"
          />
        </>
      )}
      {/* Form2 - Datos de ciudad */}
      {step === 1 && (
        <>
          <SelectField
            items={countries}
            required
            value={form?.country}
            onChange={handleChange}
            name="country"
            label="Pais"
          />
          {regions ? (
            <SelectField
              items={regions}
              required
              value={form?.region}
              onChange={handleChange}
              name="region"
              label="Región"
            />
          ) : (
            <TextField disabled label="Región" />
          )}
        </>
      )}
      {/* Form3 - Datos de contacto */}
      {step === 2 && (
        <>
          <TextField
            required
            type="email"
            value={form?.email}
            onChange={handleChange}
            name="email"
            label="E-mail corporativo"
            placeholder="Escribe tu correo corporativo"
          />
          <PhoneField
            countryDefault={form?.country || "ar"}
            value={form?.phone}
            onChange={handleChange}
            name="phone"
            label="Telefono *"
          />
        </>
      )}
      {/* Form4 - Datos de empresa */}
      {step === 3 && (
        <>
          <Col>
            <TextField
              value={form?.business}
              onChange={handleChange}
              name="business"
              label="Empresa"
              placeholder="Escribe el nombre de tu empresa"
            />
            <SelectField
              items={position}
              required
              value={form?.position}
              onChange={handleChange}
              name="position"
              label="Puesto o cargo"
            />
            {/* <TextField value={form?.position} onChange={handleChange} name="position" label="Puesto o cargo" placeholder='Escribe tu puesto o cargo'/> */}
          </Col>
          <Grid>
            {SIZE.map((size) => (
              <SizeBusiness
                active={selectSize}
                onClick={() => setSelectSize(size?.value)}
                size={size?.name}
              />
            ))}
          </Grid>
        </>
      )}
      {/* Form5 - Datos de consulta */}
      {step === 4 && (
        <>
          <TextField
            multiline
            rows={4}
            value={form?.message}
            onChange={handleChange}
            name="message"
            label="Consulta"
            placeholder="Escribe tu consulta"
          />
        </>
      )}

      {/* Navegacion */}
      <Footer justifyCenter={step > 0 ? "space-between" : "center"}>
        {step > 0 && (
          <DefaultButton
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            color="primary"
          >
            Volver
          </DefaultButton>
        )}
        {step !== 4 && (
          <DefaultButton
            onClick={handleNext}
            disabled={!valid}
            variant="contained"
            color="primary"
          >
            Continuar
          </DefaultButton>
        )}
        {step === 4 && (
          <DefaultButton
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Enviar
          </DefaultButton>
        )}
      </Footer>
    </FormWrapper>
  );
};

export default FormCases;

const SizeBusiness = (props) => {
  const { size, active, onClick } = props;

  return (
    <SizeWrapper active={active === size ? true : false} onClick={onClick}>
      <b>{size}</b>
      <p>COLABORADORES</p>
    </SizeWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 12px;
  position: relative;
  height: 100%;
`;

const Footer = styled.div`
  height: 80px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: ${(p) => p.justifyCenter};
`;

const Col = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const SizeWrapper = styled.div``

const Grid = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
`;