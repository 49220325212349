import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useFaculty from "../../hooks/useFaculty";
import DefaultText from "../common/DefaultText";
import Carousel from "react-elastic-carousel";
import UnderlinedText from "../common/UnderlinedText";

const Marks = () => {
  const { marksArray } = useFaculty();

  const CMS_URL = "https://cms.aden.org/";

  const [arrayFormat, setArrayFormat] = useState([]);

  useEffect(() => {
    if (!!marksArray && arrayFormat.length === 0) {
      let newArray = [];
      for (let index = 0; index < marksArray.length; index += 7) {
        const subArray = marksArray.slice(index, index + 7);
        newArray.push({
          subArray: subArray,
        });
      }
      setArrayFormat(newArray);
    }
  }, [marksArray]);

  return (
    <MarksWrapper>
      <DefaultText
        id="fontTitle"
        fontSize="3rem"
        fontWeight="700"
        color="#13003E"
      >
        Empresas que{" "}
        <UnderlinedText
          textDecorationColor="#BF80FF"
          textDecorationStyle="solid"
          heigt="9px"
          color="#13003E"
        >
          confían en ADEN
        </UnderlinedText>{" "}
      </DefaultText>

      <DefaultText fontSize="1.125rem" fontWeight="400" color="#13003E" lineHeight="150%">
        Más de 5.000 empresas en América Latina avalan la confiabilidad y logros
        del trabajo realizado por ADEN Educational Group
      </DefaultText>
      {arrayFormat.length > 0 && (
        <CarouselFake
          transitionMs={1000}
          showArrows={false}
          autoPlaySpeed={5000}
          itemsToShow={1}
          enableAutoPlay
        >
          {arrayFormat.map((marks) => (
            <WrapperAd>
              <BrandWrapper>
                {marks.subArray.map((subMarks) => (
                  <img
                    width="130px"
                    key={subMarks.id}
                    src={`${CMS_URL}${subMarks.attributes.url}`}
                  />
                ))}
              </BrandWrapper>
            </WrapperAd>
          ))}
        </CarouselFake>
      )}
    </MarksWrapper>
  );
};

export default Marks;

const MarksWrapper = styled.section`
  height: 467px;
  max-height: 467px;
  width: 100%;
  background-color: #f2e5ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  @media (max-width: 768px) {
    text-align: start;
    max-height: 500px;
  }
`;

const CarouselFake = styled(Carousel)`
  height: 150px;
  position: relative;
  width: 80%;
  /* padding: 0.5rem; */
  background: transparent;

  .rec-slider-container {
    margin: 0;
  }
  .rec.rec-pagination {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    display: none;
    button {
      background: #b5b6b6;
      box-shadow: none;
    }
    .rec-dot_active {
      border: none;
      background-color: #fff;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const WrapperAd = styled.div`
  width: calc(100% - 3rem);
  padding: 1rem;
  background-color: transparent;
  height: fit-content;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2rem;
  @media (max-width: 768px) {
    img {
      width: 200px;
    }
  }
`;

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
`;
