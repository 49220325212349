import React from 'react'
import styled from 'styled-components'

const DefaultText = (props) => {

    return (
        <TextWrapper {...props}/>
    )
}

export default DefaultText

const TextWrapper = styled.p`
    text-transform: none;
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight};
    text-align: ${p => p.textAlign};
    line-height: ${p => p.lineHeight};
    letter-spacing: ${ p => p.letterSpacing };
    /* font-family: 'Montserrat'; */
    /* font-style: normal; */
    padding: 8px 16px;
    color: ${p => p.color};
`