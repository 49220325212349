import React from "react";
import styled from "styled-components";
import DefaultText from "../common/DefaultText";
import DefaultButton from "../common/DefaultButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SimpleLoading from "../common/SimpleLoading";
import acropolislabHeroBg from "../../assets/images/acropolislab-hero-bg.png";

const BannerRutaFormativa = (props) => {
  const { handleOpen, attributes } = props;

  const URL_CMS = "https://cms.aden.org";

  if (!attributes) {
    return (
      <BannerWrapper id="inicioRuta">
        <SimpleLoading />
      </BannerWrapper>
    );
  }
  return (
    <BannerWrapper id="inicioRuta" bg={acropolislabHeroBg}>
      <InfoBanner>
        <DefaultText
          id="fontTitle"
          fontWeight="700"
          fontSize="1.5rem"
          color="#fff"
        >
          Rutas de Formación
        </DefaultText>
        <DefaultText
          id="fontTitle"
          fontWeight="700"
          fontSize="4rem"
          color="#fff"
        >
          {attributes.display_name}
        </DefaultText>

        <DefaultText fontWeight="400" fontSize="1.125rem" color="#fff">
          {attributes.description}{" "}
        </DefaultText>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="purpleLighter"
            variant="contained"
          >
            Empezar ahora
          </DefaultButton>
          <DefaultButton
            href="#courses"
            color="purpleLighter"
            colorFill="#D8B3FF"
            endIcon={<ArrowForwardIcon colorFill="#fff" />}
          >
            Ver cursos
          </DefaultButton>
        </Buttons>
      </InfoBanner>
      <ImageWrapper
        bg={`${URL_CMS}${attributes.cover_image.data.attributes.url}`}
      />
    </BannerWrapper>
  );
};

export default BannerRutaFormativa;

const BannerWrapper = styled.div`
  height: calc(100vh - 101px);
  padding: 0;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  background-image: url(${(p) => p.bg});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    height: auto;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;

const InfoBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  height: 100%;
  padding: 1.5rem;
  overflow: hidden;

  p {
    text-align: start;
  }
  @media (max-width: 768px) {
    height: auto;
    padding: 1.5rem 0;
    width: 100%;
    #title {
      transform: scale(0.7);
    }
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${(p) => p.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 30vh;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 12px;
  width: calc(100% - 36px);
  padding: 0 18px;
  margin-top: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
