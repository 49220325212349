import axios from "axios"

const SISAPI_URL = 'https://sisapi.aden.org/api'

export const getCountriesService = async () => {
    try {
        const req = await axios.get(`${SISAPI_URL}/v1/acropolis/miscs/countries`)
        return req.data?.response_data
    } catch (error) {
        throw Error(error)
    }
}