import React from "react";
import styled from "styled-components";
// import DefaultSpan from "../common/DefaultSpan";
import DefaultText from "../common/DefaultText";
import Image from "../common/Image";
// import notebook from "../../assets/images/notebook.png";
import laptopAdmin from "../../assets/images/laptop-admin.png";

import AddIcon from "@mui/icons-material/Add";
import DefaultButton from "../common/DefaultButton";
import UnderlinedText from "../common/UnderlinedText";

const Business = (props) => {
  const { handleOpen } = props;

  return (
    <BusinessWrapper>
      <Info>
        <DefaultText
          id="fontTitle"
          color="#13003E"
          fontWeight="700"
          fontSize="3rem"
        >
          AcrópolisLAB
          {" "}
          <UnderlinedText
            textDecorationColor="#BF80FF"
            textDecorationStyle="solid"
            heigt="9px"
            color="#13003E"
            fontWeight="700"
            fontSize="3rem"
          >
            se adapta
          </UnderlinedText>
          {/* <DefaultSpan color="#B31D15">
            se adapta
            </DefaultSpan> */}
             {" "}
          a tu empresa
        </DefaultText>
        <List>
          <Item>
            <AddIcon />
            <DefaultText fontSize="1.125rem">
              <b>Contenido ADEN:</b> cursos, workshops y microlearnings
              desarrollados por expertos
            </DefaultText>
          </Item>
          <Item>
            <AddIcon />
            <DefaultText fontSize="1.125rem">
              <b>Contenido Empresa:</b> crea tus propios cursos con ayuda del
              equipo académico de Acrópolis Lab
            </DefaultText>
          </Item>
          <Item>
            <AddIcon />
            <DefaultText fontSize="1.125rem">
              <b>Gamification:</b> dinámicas educativas que favorecen la
              fidelización y mantención de usuarios activos
            </DefaultText>
          </Item>
          <Item>
            <AddIcon />
            <DefaultText fontSize="1.125rem">
              <b>Asesoramiento personalizado:</b> ADEN te acompaña en el diseño
              de las rutas de aprendizaje y la utilización de la plataforma
            </DefaultText>
          </Item>
        </List>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="primary"
            variant="contained"
          >
            Solicitar más información
          </DefaultButton>
        </Buttons>
      </Info>
      <ImageContainer bgimg={laptopAdmin}>
        {/* <Image src={laptopAdmin} /> */}
      </ImageContainer>
    </BusinessWrapper>
  );
};

export default Business;

const BusinessWrapper = styled.section`
  height: auto;
  width: calc(100% - 200px);
  padding: 0 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "bottom top";
  margin: 4% 0;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "top   "
      "bottom";
    padding: 1rem;
    width: calc(100% - 2rem);
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 700px;
  grid-area: top;
  text-align: center;
  margin-right: 2rem;
  background-image: url(${(p) => p.bgimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  object-fit: contain;
  @media (max-width: 1100px) {
    height: 300px;
    padding: 0;
  }
`;

const Info = styled.div`
  grid-area: bottom;
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    width: 100%;
  }
  @media (max-width: 768px) {
    transform: scale(0.9);
    width: 100%;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
  @media (max-width: 768px) {
    text-align: start;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
  p,
  b {
    color: #616161;
  }
  svg {
    fill: #591AB6;
  }
`;

const Buttons = styled.div`
  width: 100%;
  margin-top: 1.125rem;
`;
