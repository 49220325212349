import React from 'react'
import styled from 'styled-components'

const Link = (props) => {

    return (
        <LinkWrapper {...props}/>
    )
}

export default Link

const LinkWrapper = styled.a`
    text-transform: none;
    font-size: ${ p => p.fontSize ? p.fontSize : "16px"};
    line-height: ${ p => p.lineHeight ? p.lineHeight : "16px"};
    font-weight: ${ p => p.fontWeight ? p.fontWeight : "700"};
    /* font-family: 'Ubuntu';
    font-style: normal; */
    text-decoration: none;
    cursor: pointer;
    padding: 8px 16px;
    color: ${p => p.color};
`