import React from "react";
import styled from "styled-components";
import DefaultButton from "../common/DefaultButton";
import DefaultText from "../common/DefaultText";
import Image from "../common/Image";
// import banner from "../../assets/images/banner_leader.png";
import bannerImg from "../../assets/images/business-people-1.svg";
import bannerBackground from "../../assets/images/watermark-crop3.png";
import acropolislabHeroBg from "../../assets/images/acropolislab-hero-bg.png";

import UnderlinedText from "../common/UnderlinedText";

const BannerLeader = (props) => {
  const { handleOpen } = props;

  return (
    <BannerWrapper>
      <Card
        bgi={acropolislabHeroBg}
        bgLetter={bannerBackground}
        //   im={bannerBackground}
      >
        <Info>
          <DefaultText
            fontSize="1.125rem"
            fontWeight="500"
            color="#fff"
            letterSpacing="2.5px"
          >
            POTENCIA LAS FORTALEZAS DE TU EQUIPO
          </DefaultText>
          <DefaultText
            id="fontTitle"
            fontSize="2.5rem"
            color="#fff"
            fontWeight="600"
          >
            ADEN te acompaña para que seas un{" "}
            <UnderlinedText
              textDecorationColor="#13003E"
              textDecorationStyle="solid"
              heigt="0.5rem"
              color="#fff"
            >
              líder integral
            </UnderlinedText>{" "}
          </DefaultText>
          <Button onClick={handleOpen}>Solicitar demo</Button>
        </Info>
        <ImageFake src={bannerImg} />
      </Card>
    </BannerWrapper>
  );
};

export default BannerLeader;

const BannerWrapper = styled.section`
  height: auto;
  width: calc(100% - 300px);
  padding: 0 150px;
  display: flex;
  margin: 4% 0;
  overflow: hidden;

  @media (max-width: 1440px) {
    padding: 1rem 2rem;
    width: calc(100% - 4rem);
  }
`;

const Card = styled.div`
  border-radius: 20px;
  background-image: url(${(p) => p.bgi}), url(${(p) => p.bgLetter});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: contain;
  /* background-image: ${(p) => p.bgi}; */
  /* background-color: #16151e; */
  height: 310px;
  /* max-width: 1464px; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1120px) {
    max-width: 100%;
    grid-template-columns: 2fr 1fr;
    div p {
      font-size: 1rem;
      gap: 0.85rem;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
    height: auto;
    padding: 12px 0;
    img {
      display: none;
    }
    div p {
      width: 100%;
    }
  }
`;

const Info = styled.div`
  height: 100%;
  padding: 0 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
`;

const Button = styled(DefaultButton)`
  background-color: #d8b3ff !important;
  color: #2e0a6c !important;
  margin-left: 1rem !important;
`;

const ImageFake = styled(Image)`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  align-self: flex-end;
  justify-self: end;
  /* 
  img {
    object-fit: cover;
    padding: 0;
  } */
`;
