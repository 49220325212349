import { useEffect, useState } from "react"
import CardTeacher from "../components/faculty/CardTeacher"
import { getTutorsService } from "../services/cms"

const IMAGE_URL = 'https://cms.aden.org'

const useFaculty = () => {

    const [faculty, setFaculty] = useState(null)
    const [facultyCards, setFacultyCards] = useState(null)
    const [marksArray, setMarksArray] = useState(null)

    useEffect(() => {getTutors()}, [])

    const getTutors = async () => {
        try {
            const req = await getTutorsService()
            setFaculty(req?.attributes?.faculty)
            setFacultyCards(req?.attributes?.faculty.map(item => (<CardTeacher title={item.job_title} name={item.name} image={`${IMAGE_URL}${item?.picture?.data?.attributes?.url}`}/>)))
            setMarksArray(req?.attributes?.companies?.data)
        } catch (error) {
            console.error(error);
        }
    }


    return { faculty, facultyCards, marksArray }
}

export default useFaculty