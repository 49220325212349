import React from "react";
import styled from "styled-components";
import DefaultButton from "../common/DefaultButton";
// import DefaultSpan from "../common/DefaultSpan";
import DefaultText from "../common/DefaultText";
import Image from "../common/Image";
// import chart from "../../assets/images/charts.png";
import chart from "../../assets/images/acropolis-charts.png";

import UnderlinedText from "../common/UnderlinedText";

const Monitor = (props) => {
  const { handleOpen } = props;

  return (
    <MonitorWrapper>
      <Images id="image" bgimg={chart}>
        {/* <Image width="700px" src={chart} /> */}
      </Images>
      <Information>
        <DefaultText
          id="fontTitle"
          color="#13003E"
          fontSize="48px"
          fontWeight="700"
        >
          Monitorea el progreso y los resultados{" "}
          <UnderlinedText
            textDecorationColor="#BF80FF"
            textDecorationStyle="solid"
            heigt="9px"
            color="#13003E"
            fontWeight="700"
            fontSize="48px"
            lineHeight="59px"
          >
            24/7
          </UnderlinedText>
          {/* <DefaultSpan color="#b31d15">24/7</DefaultSpan> */}
        </DefaultText>
        <DefaultText color="#616161" fontSize="18px" fontWeight="400">
          Tendrás acceso a reportes personalizados y accesos simples para el
          seguimiento y tracking de performance
        </DefaultText>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="primary"
            variant="contained"
          >
            Empezar ahora
          </DefaultButton>
        </Buttons>
      </Information>
    </MonitorWrapper>
  );
};

export default Monitor;

const MonitorWrapper = styled.section`
  height: auto;
  width: calc(100% - 300px);
  padding: 0 150px;
  margin: 4% 0;
  display: grid;
  grid-template-areas: "top bottom";
  grid-template-columns: 40vw 40vw;
  gap: 2rem;

  @media (max-width: 1100px) {
    padding: 12px;
    width: calc(100% - 24px);
  }

  @media (max-width: 768px) {
    grid-template-areas:
      "top   "
      "bottom";
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const Images = styled.div`
 width: 100%;
  height: 450px;
  grid-area: top;
  text-align: center;
  margin-right: 2rem;
  background-image: url(${(p) => p.bgimg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  object-fit: contain;
  @media (max-width: 1100px) {
    height: 300px;
    padding: 0;
  }
/* 
  grid-area: top;
  text-align: center;
  margin-right: 2rem;
  img {
    padding: 2rem;
    width: 70%;
  }
  @media (max-width: 768px) {
    img {
      width: 50%;
      padding: 0;
    }
  } */
`;

const Information = styled.div`
  width: 100%;
  grid-area: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  @media (max-width: 768px) {
    width: 100%;
    text-align: start;
    #fontTitle {
      text-align: start;
    }
  }
`;

const Buttons = styled.div`
  width: 100%;
  margin-top: 18px;
`;
