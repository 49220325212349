import React from "react";
import styled from "styled-components";
// import logo from "../../assets/images/alab-color.png";
import logo from "../../assets/images/acropolislab-logo-fullcolor.svg";
// import letters from '../../assets/images/letters.png'
import letters from "../../assets/images/acropolis-letters-purple-light-m.png";
import Image from "../common/Image";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DefaultText from "../common/DefaultText";
import useCMS from "../../hooks/useCMS";

const Contact = () => {
  const { phone, email } = useCMS();

  const handleClickEmail = () => window.open(`mailto:${email}`);

  return (
    <ContactWrapper bg={letters} id="contact">
      <Container>
        <Image width={"150px"} src={logo} />
        <Buttons>
          {!!email && (
            <MyButton bg="#591AB6" onClick={handleClickEmail}>
              <IconContent>
                <EmailIcon />
              </IconContent>
              <Data>
                <DefaultText fontSize="14px" color="#fff" fontWeight="700">
                  ¡Envíanos un mail!
                </DefaultText>
                <DefaultText fontSize="18px" color="#fff" fontWeight="400">
                  {email}
                </DefaultText>
              </Data>
            </MyButton>
          )}
          {!!phone && (
            <MyButton bg="#13003E">
              <IconContent>
                <LocalPhoneIcon />
              </IconContent>
              <Data>
                <DefaultText fontSize="14px" color="#fff" fontWeight="700">
                  ¡Habla con un asesor!
                </DefaultText>
                <DefaultText fontSize="16px" color="#fff" fontWeight="600">
                  {phone}
                </DefaultText>
              </Data>
            </MyButton>
          )}
        </Buttons>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;

const ContactWrapper = styled.section`
  height: 270px;
  width: 100%;
  display: flex;
  position: relative;

  &&::before {
    content: "";
    background-image: url(${(p) => p.bg});
    background-size: 250px;
    background-repeat: repeat;
    background-position: center center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.059;
    z-index: 0;
  }
`;

const Container = styled.div`
  width: calc(100% - 300px);
  padding: 0 150px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    img {
      display: none;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const MyButton = styled.div`
  width: 276px;
  height: 90px;
  border-radius: 20px;
  background-color: ${(p) => p.bg};
  display: flex;
  cursor: pointer;
`;

const IconContent = styled.div`
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    fill: #fff;
    font-size: 32px;
  }
`;

const Data = styled.div`
  width: calc(100% - 100px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  p {
    padding: 4px 16px;
  }
`;
