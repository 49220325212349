import React from 'react'
import styled from 'styled-components'
import useCMS from '../../hooks/useCMS'
// import letters from '../../assets/images/letters.png'
import lettersPurpleLightTM from '../../assets/images/acropolis-letters-purple-light-t-m.png'


const Video = () => {

    const { video } = useCMS()

    if (!video) {
        return false
    }

    return (
        <VideoWrapper bg={lettersPurpleLightTM}>
            <Iframe src={video}/>
        </VideoWrapper>
    )
}

export default Video

const VideoWrapper = styled.section`
    height: 90vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${p => p.bg});
    background-size: 600px;
    @media (max-width: 768px){
        height: auto;
        padding: 5% 0;
    }
    
`

const Iframe = styled.iframe`
    border-radius: 20px;
    width: 60%;
    height: 70%;
    @media (max-width: 768px){
        width: 100%;
        height: 200px;
        margin: 12px;
    }
`