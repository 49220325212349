import React, { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import Marks from "../components/marks/Marks";
import Video from "../components/video/Video";
import Information from "../components/information/Information";
import Content from "../components/content/Content";
import Faculty from "../components/faculty/Faculty";
import BannerLeader from "../components/banner-leader/BannerLeader";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import Social from "../components/social/Social";
import DialogForm from "../components/common/DialogForm";
import useRouteBySlugForm from "../hooks/useRouteBySlugForm";
import BannerRutaFormativa from "../components/banner/BannerRutaFormativa";
import CoursesInfo from "../components/routes/CoursesInfo";
import SimpleLoading from "../components/common/SimpleLoading";
import IntegralSolution from "../components/business/IntegralSolution";
import Vector from "../components/common/Vector";
import { titleHTML } from "../services/titleHTML";

const RutaDeFormacion = () => {
  const { route } = useRouteBySlugForm();

  titleHTML(`${route?.attributes.display_name}`);

console.log('route', route)
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  if (!route) {
    <SimpleLoading />;
  }
  return (
    <>
      <Navbar handleOpen={handleOpen} />
      <BannerRutaFormativa
        handleOpen={handleOpen}
        attributes={route?.attributes}
      />
      <Vector color="#BF80FF"/>
      <Content />
      <CoursesInfo
        attributes={route?.attributes}
        courses={route?.attributes?.cursos?.data}
      />
      <Information />
      <Video />
      <Marks />
      <IntegralSolution />
      <Faculty handleOpen={handleOpen} />
      <BannerLeader handleOpen={handleOpen} />
      <Contact />
      <Footer />
      <Social />
      <DialogForm handleClose={handleClose} open={open} />
    </>
  );
};

export default RutaDeFormacion;
