import React from 'react'
import styled from 'styled-components'
import DefaultText from '../common/DefaultText'
import facebook from '../../assets/images/facebook.svg'
import twitter from '../../assets/images/twitter.svg'
import instagram from '../../assets/images/instagram.svg'
import linkedin from '../../assets/images/linkedin.svg'
import youtube from '../../assets/images/youtube.svg'
import Image from '../common/Image'
import useCMS from '../../hooks/useCMS'
import { useEffect } from 'react'
import { useState } from 'react'

const Social = () => {

    const { social } = useCMS()

    const [socialRed, setSocialRed] = useState(null)

    useEffect(() => {
        social && setterIconsSocial()
    }, [social])
    

    const setterIconsSocial = () => {
        const cases = {
            facebook,
            twitter,
            instagram,
            youtube,
            linkedin
        }
        let result = social.map(element => ({...element, icon: cases[element.name]}));
        setSocialRed(result)
    }

    return (
        <SocialWrapper>
            <DefaultText color="#fff" fontSize="18px">Copyright © AcrópolisLAB by ADEN  |   UNIVERSITE DE MANAGEMENT DE SUISSE, S.A</DefaultText>
            <SocialBtn>
                {socialRed && socialRed.map(social => <IconWrapper onClick={() => window.open(social?.url)} title={`Ir a ${social?.name}`}><Image src={social?.icon}/></IconWrapper>)}
            </SocialBtn>
        </SocialWrapper>
    )
}

export default Social

const SocialWrapper = styled.div`
    height: 150px;
    background-color: #13003E;
    display: flex;
    padding: 0 100px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px){
        display: flex;
        flex-direction: column-reverse;
        gap: 12px;
        padding: 16px 0;
        height: auto;
        text-align: center;
    }
`

const SocialBtn = styled.div`
    display: flex;
    gap: 6px;
`

const IconWrapper = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`