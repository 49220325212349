import { useEffect, useState } from "react"
import { getRoutesService } from "../services/cms"


const useRoutesForm = () => {

    const [routes, setRoutes] = useState(null)

    useEffect(() => {getRoutes()}, [])

    const getRoutes = async () => {
        try {
            const req = await getRoutesService()
            // console.log('req', req)
            setRoutes(req)
        } catch (error) {
            console.error(error);
        }
    }


    return { routes }
}

export default useRoutesForm