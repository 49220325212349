import { Dialog, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import FormStep from "./form/stepper/FormStep";
import SingleForm from "./form/simple/SingleForm";
// import letters from '../../assets/images/letters-blue.png'
import letters from "../../assets/images/acropolislab-hero-bg.png";

const DialogForm = (props) => {
  const { open, handleClose } = props;

  return (
    <>
      <DialogFake bg={letters} fullScreen open={open} onClose={handleClose}>
        <Container>
          <IconClose>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </IconClose>
          <SingleForm />
        </Container>
      </DialogFake>
    </>
  );
};

export default DialogForm;

const DialogFake = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    ::before {
      content: "";
      opacity: 0.7;
      background-image: url(${(p) => p.bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
`;

const Container = styled.div`
  position: relative;
  width: 40%;
  min-height: 500px;
  /* height: 40; */
  /* min-height: 60vh; */
  border-radius: 20px;
  background-color: #fff;
  transition: ease-in-out 0.3s all;
  z-index: 11;
  padding: 20px;
  @media (max-width: 768px) {
    width: auto;
    min-width: 320px;
    border-radius: 0;
  }
`;

const IconClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    fill: #000000;
  }
  
  @media (max-width: 425px) {
    top: 5px;
    right: 5px;
  }
`;
