import React from "react";
import styled from "styled-components";
import DefaultText from "../common/DefaultText";

const Information = () => {
  return (
    <InfoWrapper>
      <div>
        <DefaultText
          color="#fff"
          fontSize="2.375rem"
          fontWeight="700"
          lineHeight="2.875rem"
        >
          Plataforma user friendly + Customer Support
        </DefaultText>
      </div>
      <div>
        <DefaultText
          color="#fff"
          fontSize="1.25rem"
          fontWeight="400"
          lineHeight="140%"
        >
          Pensada para una plena experiencia de usuario, la plataforma de
          AcrópolisLAB te permite la asignación de contenidos para el desarrollo
          de tus colaboradores de acuerdo a sus necesidades.
        </DefaultText>
      </div>
      <div>
        <DefaultText
          color="#fff"
          fontSize="1.25rem"
          fontWeight="400"
          lineHeight="140%"
        >
          Tanto el consumidor final como el gestor de la plataforma, podrán
          acceder a la visualización de reportes 24/7 y la gestión de
          contenidos, acompañados de un ejecutivo ADEN si así lo precisan.
        </DefaultText>
      </div>
    </InfoWrapper>
  );
};

export default Information;

const InfoWrapper = styled.section`
  background-color: #591ab6;
  height: 260px;
  width: calc(100% - 200px);
  padding: 0 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  div {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    padding: 1.5rem;
  }

  @media (max-width: 1440px) {
    width: calc(100% - 20px);
    padding: 10px;
    div {
      padding: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
    width: calc(100% - 1.5rem);
    padding: 0.75rem;
    p {
      transform: scale(0.85);
    }
  }
`;
