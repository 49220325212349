import React from 'react'
import styled from 'styled-components'


const Vector = (props) => {
  return (
    <VectorFake {...props}/>
  )
}

export default Vector

const VectorFake=styled.div`
    max-height:65px;
    min-height: 27px;
    padding: 0;
    background-color: ${p => p.color};
`;