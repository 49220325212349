import { useEffect, useState } from 'react'
import { getRegionsServices } from '../services/aden'
import { getCountriesService } from '../services/sisapi'

const useCountries = () => {

    const [countries, setCountries] = useState(null)
    const [regions, setRegions] = useState(null)

    useEffect(() => {
        getCountries()
    }, [])

    const getCountries = async () => {
        const req = await getCountriesService()
        try {
            setCountries(req)
        } catch (error) {
            console.error("No se puedieron obtener los paises");
        }
    }

    const handleRegion = async code => {
        setRegions(null)
        const req = await getRegionsServices(code)
        try {
            sanitizeRegion(req)
        } catch (error) {
            console.error("No se puedieron obtener los paises");
        }
    }

    const sanitizeRegion = list => {
        if (typeof list === 'object') {
            let array = []
            for (const key in list) {
                array = [...array, { id: list[key]?.city , name: list[key]?.city }]
            }
            setRegions(array)
        }
        else{
            setRegions([])
        }
    }

    return { countries, regions, handleRegion }
}

export default useCountries