import React from "react";
import styled from "styled-components";
import useRoutesForm from "../../hooks/useRoutesForm";
import { useNavigateToTop } from "../../hooks/useNavigateToTop";
import DefaultText from "../common/DefaultText";
import Image from "../common/Image";
import SimpleLoading from "../common/SimpleLoading";

const URL_CMS = "https://cms.aden.org";

const RoutesInfo = () => {
  const { routes } = useRoutesForm();
  // console.log('routes', routes)
  const navigateToTop = useNavigateToTop();

  if (!routes) {
    return (
      <RoutesWrapper id="routes">
        <SimpleLoading padding="2rem" />;
      </RoutesWrapper>
    );
  }
  return (
    <RoutesWrapper id="routes">
      <DefaultText color="#591AB6" fontSize="24px">
        RUTAS DE FORMACIÓN
      </DefaultText>
      <DefaultText
        id="fontTitle"
        color="#13003E"
        fontSize="3rem"
        fontWeight="700"
      >
        Potencia las habilidades de tu equipo
      </DefaultText>
      <Grid>
        {routes?.map((r) => (
          <CardRoute 
          onClick={() => 
          navigateToTop(`/ruta/${r.attributes?.slug}`)
          // navigateToTop(`/ruta/${r.attributes?.display_name.toLocaleLowerCase().normalize('NFD').replace(/ /g, '-')}`)
          }>
            <div>
              <Image
                width={"36px"}
                src={`${URL_CMS}${r?.attributes?.icon?.data?.attributes?.url}`}
              />
            </div>
            <div>
              <Text color="#616161" fontSize="12px">
                {r?.attributes?.cant_cursos} CURSOS
              </Text>
              <Text color="#09001F" fontSize="18px" fontWeight="500">
                {r?.attributes?.display_name}
              </Text>
            </div>
          </CardRoute>
        ))}
      </Grid>
    </RoutesWrapper>
  );
};

export default RoutesInfo;

const RoutesWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 0;
  margin: 4% 0;
  height: auto;
  @media (max-width: 768px) {
    text-align: start;
  }
`;

const Grid = styled.div`
  margin-top: 24px;
  display: grid;
  width: 90%;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  @media (max-width: 768px) {
    /* display: flex;
        flex-direction: column;
        text-align: start;
        align-items: center; */
    grid-template-columns: repeat(auto-fit, minmax(130px, 0.5fr));
    justify-items: center;
    width: 94%;
  }
`;

const CardRoute = styled.div`
  width: calc(278px - 16px);
  height: 140px;
  padding-left: 16px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: ease-in-out 0.3s all;
  overflow: hidden;

  :hover {
    height: 144px;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  }

  @media (max-width: 768px) {
    width: 150px;
    overflow: hidden;
    div {
      transform: scale(0.95);
      overflow: hidden;
      width: 100%;
    }
  }

  img {
    color: #591ab6 !important;
  }
`;

const Text = styled.div`
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color};
  text-overflow: ellipsis;
`;
