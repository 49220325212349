import { useState } from "react";
import DefaultText from "../../DefaultText";
import styled from "styled-components";
import useForm from "../../../../hooks/useForm";
import { TaskAlt } from "@mui/icons-material";

// COMPONENTS
import FormFields from "./FormFields";

const SingleForm = () => {
  const [openSuccess, setOpenSuccess] = useState(false);

  return (
    <>
      {openSuccess ? (
        <SuccessWrapper>
          <DefaultText
            textAlign="center"
            color="#000000"
            fontSize="24px"
            fontWeight="900"
          >
            ¡Hemos recibido tu consulta, pronto un asesor de AcropolisLab se
            pondrá en contacto contigo!
          </DefaultText>
          <TaskAlt fontSize="large" color="success" />
        </SuccessWrapper>
      ) : (
        <FormWrapper>
          <TextWrapper>
            <DefaultText
              textAlign="center"
              color="#000000"
              fontSize="26px"
              fontWeight="900"
            >
              ¡Solicita tu demo!
            </DefaultText>
            <DefaultText
              textAlign="center"
              color="#787878"
              fontSize="16px"
              fontWeight="500"
            >
              Registrate ahora y mejora las habilidades de gestión del personal
              de tu empresa
            </DefaultText>
          </TextWrapper>
          <InputsWrapper>
            <FormFields setOpenSuccess={setOpenSuccess} />
          </InputsWrapper>
        </FormWrapper>
      )}
    </>
  );
};

export default SingleForm;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputsWrapper = styled.div``;

const SuccessWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
