import React from "react";
import styled from "styled-components";
import DefaultButton from "../common/DefaultButton";
// import DefaultSpan from "../common/DefaultSpan";
import DefaultText from "../common/DefaultText";
// import Image from "../common/Image";
// import networking from "../../assets/images/networking.png";
import networking from "../../assets/images/acropolis-networking.png";

import UnderlinedText from "../common/UnderlinedText";

const Web = (props) => {
  const { handleOpen } = props;

  return (
    <WebrWrapper>
      <Information>
        <DefaultText
          id="fontTitle"
          color="#13003E"
          fontSize="48px"
          fontWeight="700"
        >
          Conecta con la red de profesionales y ejecutivos de{" "}
          <UnderlinedText
            textDecorationColor="#BF80FF"
            textDecorationStyle="solid"
            heigt="9px"
            color="#13003E"
          >
            ADEN
          </UnderlinedText>{" "}
          {/* <DefaultSpan color="#b31d15">ADEN</DefaultSpan> */}
        </DefaultText>
        <TextInfo>
          <DefaultText color="#616161" fontSize="18px" fontWeight="400">
            Podrás brindarle acceso a tus colaboradores a la red de networking
            de ADEN
          </DefaultText>
        </TextInfo>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="primary"
            variant="contained"
          >
            Solicitar más información
          </DefaultButton>
        </Buttons>
      </Information>
      {/* <Information> */}
      <Image width="70%" bgi={networking} />
      {/* </Information> */}
    </WebrWrapper>
  );
};

export default Web;

const WebrWrapper = styled.section`
  height: auto;
  width: calc(100% - 300px);
  padding: 0 150px;
  margin: 4% 0;
  display: grid;
  grid-template-areas: "top bottom";
  grid-template-columns: 40vw 40vw;
  gap: 2rem;

  /* @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    flex-direction: column;
    div {
      width: 100%;
      text-align: center;
      button {
        display: none;
      }
    }
  } */
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "top   "
      "bottom";
    padding: 1rem;
    width: calc(100% - 2rem);
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
`;

const Information = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Buttons = styled.div`
  width: 100%;
  padding-left: 18px;
  margin-top: 18px;
`;

const TextInfo = styled.div`
  width: 100%;
`;


const Image = styled.div`
  width: 100%;
  height: 500px;
  grid-area: top;
  text-align: center;
  margin-right: 2rem;
  background-image: url(${(p) => p.bgi});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  object-fit: contain;
  @media (max-width: 1100px) {
    height: 300px;
    padding: 0;
  }
`;