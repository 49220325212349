import React from 'react'
import styled from 'styled-components'
import DefaultText from '../common/DefaultText'

const CardTeacher = (props) => {

    const { title, image, name } = props

    return (
        <Container bg={image}>
            <FooterGradient>
                <DefaultText fontSize="18px" fontWeight="700" color="#fff">{name}</DefaultText>
                <DefaultText fontSize="14px" fontWeight="400" color="#fff">{title}</DefaultText>
            </FooterGradient>
        </Container>
    )
}

export default CardTeacher

const Container = styled.div`
    height: 398px;
    width: 260px;
    border-radius: 20px;
    background-color: #3333;
    background-image: url(${p => p.bg});
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    position: relative;
`

const FooterGradient = styled.div`
    background: rgb(76,76,76);
    background: linear-gradient(0deg, rgba(76,76,76,0.95) 0%, rgba(224,224,224,0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100% - 12px);
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 12px;
    gap: 6px;
    p{
        padding: 0 12px;
    }
`