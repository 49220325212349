import { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, Snackbar, Alert, CircularProgress } from "@mui/material";
import { Warning } from "@mui/icons-material";
import SelectField from "../../SelectField";
import PhoneField from "../../PhoneInput";
import useCountries from "../../../../hooks/useCountries";
import useForm from "../../../../hooks/useForm";
import DefaultButton from "../../DefaultButton";

const FormFields = (props) => {
  const { setOpenSuccess } = props;

  // CUSTOM-HOOK
  const {
    form,
    valid,
    handleChange,
    countries,
    handleSubmit,
    openSkackbar,
    handleCloseSnackbar,
    skackbarMessage,
    isLoading,
    isSubmitted,
  } = useForm();

  useEffect(() => {
    isSubmitted && setOpenSuccess(true)
  }, [isSubmitted]);
  
  const SIZE = [
    {
      id: 1,
      name: "1 - 5",
    },
    {
      id: 2,
      name: "6 - 10",
    },
    {
      id: 3,
      name: "11 - 50",
    },
    {
      id: 4,
      name: "51 - 100",
    },
    {
      id: 5,
      name: "101 - 500",
    },
    {
      id: 6,
      name: "+500",
    },
  ];

  return (
    <Container>
      <FieldsScroller>
        <FieldsContainer>
          <TextField
            required
            value={form?.name}
            onChange={handleChange}
            name="name"
            label="Nombre"
            placeholder="Escribe tu nombre"
          />
          <TextField
            required
            value={form?.lastName}
            onChange={handleChange}
            name="lastName"
            label="Apellido"
            placeholder="Escribe tu apellido"
          />
          <SelectField
            items={countries}
            required
            value={form?.country}
            onChange={handleChange}
            name="country"
            label="Pais *"
          />
          <TextField
            required
            type="email"
            value={form?.email}
            onChange={handleChange}
            name="email"
            label="E-mail corporativo"
            placeholder="Escribe tu correo corporativo"
          />
          <PhoneField
            countryDefault={form?.country || "ar"}
            value={form?.phone}
            onChange={handleChange}
            name="phone"
            label="Telefono *"
          />
          <TextField
            value={form?.business}
            onChange={handleChange}
            name="business"
            label="Empresa"
            placeholder="Escribe el nombre de tu empresa"
          />
          <SelectField
            items={SIZE}
            value={form?.employees}
            onChange={handleChange}
            name="employees"
            label="Colaboradores"
          />
        </FieldsContainer>
      </FieldsScroller>
      <ButtonWrapper>
        {isSubmitted ? (
          <DefaultButton height="3rem" variant="outlined">
            Ya solicitaste tu demo!
          </DefaultButton>
        ) : (
          <DefaultButton
            height="3rem"
            variant="contained"
            disabled={!valid}
            onClick={handleSubmit}
            width="12rem"
          >
            {isLoading ? (
              <CircularProgress color="tertiary" />
            ) : (
              "Solicitar demo"
            )}
          </DefaultButton>
        )}
      </ButtonWrapper>
      <FakeSnackbar
        open={openSkackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <FakeAlert open={openSkackbar} severity="warning" variant="filled">
          {skackbarMessage}
        </FakeAlert>
      </FakeSnackbar>
    </Container>
  );
};

export default FormFields;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
`;

const FieldsScroller = styled.div`
  @media (max-width: 425px) {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 390px;

    ::-webkit-scrollbar {
      width: 7px;
      border-radius: 5px;
      scroll-margin-block: 5px;
      left: -100px;
    }

    ::-webkit-scrollbar-thumb {
      left: 10px;
      background: rgba(89, 26, 182, 0.5);
      border-radius: 4px;
    }
  }
`;

const FieldsContainer = styled.div`
  height: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

const FakeSnackbar = styled(Snackbar)`
  &.MuiPaper-root.css-cpgvjg-MuiSnackbar-root {
    width: 100%;
    font-weight: 500;
  }
`;

const FakeAlert = styled(Alert)`
  -webkit-text-fill-color: #ffffff;
  &.MuiAlert-standardWarningMuiAlert-root {
    background-color: ${(p) => p.open && "rgb(245, 124, 0)"} !important;
    svg {
      fill: #ffffff;
    }
  }
`;

const ButtonWrapper = styled.div``;
