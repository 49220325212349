import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import styled from 'styled-components'

const PhoneField = (props) => {

    const { name, value, onChange, label, countryDefault } = props

    return <PhoneInputFake
        country={countryDefault?.toLowerCase()}
        specialLabel={label}
        inputProps={{
            required: true,
        }}
        value={value}
        onChange={phone => onChange({ target: { name, value: phone } })}
    />
}

export default PhoneField

const PhoneInputFake = styled(PhoneInput)`
    width: 100% !important;
    input{
        width: 100% !important;
    }
`