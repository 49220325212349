import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import AlabLogo from "../common/AlabLogo";
import DefaultButton from "../common/DefaultButton";
import Link from "../common/Link";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const ALAB_URL = "https://app.acropolislab.com/auth";

const Navbar = (props) => {
  const { handleOpen } = props;

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  if (window.innerWidth < 769) {
    return (
      <>
        <NavMobile>
          <AlabLogo />
          <IconButtonFake onClick={toggleMenu}>
            <MenuIcon />
          </IconButtonFake>
        </NavMobile>
        <MenuList open={open}>
          <Buttons>
            <Link href="/#routes" color="#13003E">
              Rutas de formación
            </Link>
            <Link href="#faculty" color="#13003E">
              Faculty
            </Link>
            <Link href="#contact" color="#13003E">
              Contacto
            </Link>
          </Buttons>
          <Divider />
          <ButtonsMobile>
            <DefaultButton
              onClick={() => window.open(ALAB_URL, "_self")}
              color="primary"
              variant="outlined"
            >
              Iniciar sesión
            </DefaultButton>
            <DefaultButton
              onClick={handleOpen}
              color="primary"
              variant="contained"
            >
              Solicitar demo
            </DefaultButton>
          </ButtonsMobile>
        </MenuList>
      </>
    );
  }

  return (
    <NavbarContainer>
      <LeftNav>
        <AlabLogo />
        <Buttons>
          <Link href="/#routes" color="#13003E">
            Rutas de formación
          </Link>
          <Link href="#faculty" color="#13003E">
            Faculty
          </Link>
          <Link href="#contact" color="#13003E">
            Contacto
          </Link>
        </Buttons>
      </LeftNav>
      <RightNav>
        <DefaultButton
          onClick={() => window.open(ALAB_URL, "_self")}
          color="primary"
          variant="text"
        >
          Iniciar sesión
        </DefaultButton>
        <DefaultButton onClick={handleOpen} color="primary" variant="contained">
          Solicitar demo
        </DefaultButton>
      </RightNav>
    </NavbarContainer>
  );
};

export default Navbar;

const NavbarContainer = styled.nav`
  width: calc(100% - 200px);
  height: 100px;
  background: #ffffff;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  position: sticky;
  top: 0;
  padding: 0 100px;
  display: flex;
  z-index: 2;
  justify-content: space-between;
  @media (max-width: 1440px) {
    width: calc(100% - 50px);
    padding: 0 25px;
  }
`;

const LeftNav = styled.div`
  display: flex;
  height: 100%;
`;

const RightNav = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 12px;
  @media (max-width: 768px) {
    display: none;
    /* position: fixed; */
  }
`;

const Buttons = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0 24px;
  @media (max-width: 768px) {
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
    flex-direction: column;
  }
`;

// Mobile

const NavMobile = styled.nav`
  width: calc(100% - 62px);
  max-height: 70px;
  background: #ffffff;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  position: fixed;
  top: 0;
  padding: 0 36px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  align-items: center;
  overflow-x: visible !important;
`;

const IconButtonFake = styled(IconButton)`
  width: 52px;
  height: 52px;
`;

const MenuList = styled.div`
  position: fixed;
  top: ${(p) => (p.open ? "70px" : "-360px")};
  left: 0;
  height: 360px;
  background-color: #ffffff;
  z-index: 2;
  width: 100%;
  transition: ease-in-out 0.3s all;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
    0px 8px 24px -4px rgba(24, 39, 75, 0.08);
`;

const Divider = styled.div`
  height: 2px;
  background-color: #eee;
  width: calc(100% - 36px);
  margin: 24px 18px;
`;

const ButtonsMobile = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
