import { useEffect, useState } from "react"
import { getDataService, getVideoService } from "../services/cms"


const useCMS = () => {

    const [video, setVideo] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [calendar, setCalendar] = useState(null)
    const [footer, setFooter] = useState(null)
    const [social, setSocial] = useState(null)

    useEffect(() => {getVideo()}, [])

    const getVideo = async () => {
        try {
            const req = await getDataService()
            setVideo(req?.attributes?.video_link)
            setEmail(req?.attributes?.contact_email)
            setPhone(req?.attributes?.contact_phone)
            setCalendar(req?.attributes?.calendly_link)
            setFooter(req?.attributes?.footer_links)
            setSocial(req?.attributes?.social_links)
        } catch (error) {
            console.error(error);
        }
    }


    return { video, email, phone, calendar, footer, social }
}

export default useCMS