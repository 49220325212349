import React from 'react'
import styled from 'styled-components'
import Image from './Image'
import chat from '../../assets/images/chat.png'

const ChatUser = (props) => {

    const { step, form } = props

    return (
        <ChatWrapper>
            <ImageWrapper>
                <Image src={chat}/>
            </ImageWrapper>
            
                {step === 0 && <Message>¡Hola! Mi nombre es Irene Kopper. Para empezar, ¿Cuál es tu nombre?</Message>}
                {step === 1 && <Message>¡Perfecto {form?.name}! ¿De dónde eres?</Message>}
                {step === 2 && <Message>¡Buenísimo! ¿Cómo podemos contactarte?</Message>}
                {step === 3 && <Message>¡Ya casi! Cuentame de tu empresa:</Message>}
                {step === 4 && <Message>Por último, ¿Tienes alguna duda o consulta adicional</Message>}
        </ChatWrapper>
    )
}

export default ChatUser

const ChatWrapper = styled.div`
    display: flex;
    margin: 24px 0;
    align-items: center;
    gap: 12px;
`

const ImageWrapper = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
`

const Message = styled.div`
    width: calc(100% - 100px);
    background-color: #f9f9f9;
    color: #616161;
    padding: 16px;
    border-radius: 0px 20px 20px 20px;
    font-size: 18px;
    animation: fadein 1s;
    @keyframes fadein {
        from { opacity: 0}
        to   { opacity: 1}
    }
`