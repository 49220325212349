import React from 'react';
import styled from 'styled-components';

const UnderlinedText = (props) => {
    const { textDecorationColor,textDecorationStyle, heigt, color } = props;

    // RETURN
    return (
        <FakeP
            textDecorationColor={textDecorationColor}
            textDecorationStyle={textDecorationStyle}
            heigt={heigt}
            color={color}
            {...props}
        >
            {props.children}
        </FakeP>
    );
};

export default UnderlinedText;

const FakeP = styled.span`
    color: ${p => p.color};
    text-decoration-color:  ${(p) => p.textDecorationColor};
    text-decoration-style: ${(p) => p.textDecorationStyle};
    text-decoration-line: underline;
    text-decoration-thickness: ${(p) => p.heigt};
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight};
    text-align: ${p => p.textAlign};
    line-height: ${p => p.lineHeight};
    /* font-family: 'Montserrat'; */
    /* font-style: normal; */
   
`;
