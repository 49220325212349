import React, { useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import Banner from '../components/banner/Banner'
import Marks from '../components/marks/Marks'
import Video from '../components/video/Video'
import Information from '../components/information/Information'
import Business from '../components/business/Business'
import Monitor from '../components/monitor/Monitor'
import Content from '../components/content/Content'
import RoutesInfo from '../components/routes/RoutesInfo'
import AutoGestion from '../components/autogestion/AutoGestion'
import Web from '../components/red/Red'
import Faculty from '../components/faculty/Faculty'
import BannerLeader from '../components/banner-leader/BannerLeader'
import Contact from '../components/contact/Contact'
import Footer from '../components/footer/Footer'
import Social from '../components/social/Social'
import DialogForm from '../components/common/DialogForm'
import useQueryParams from '../hooks/useQueryParams'
import Vector from '../components/common/Vector'

const AcropolisLab = () => {

    const { demo = false } = useQueryParams()
    const [open, setOpen] = useState(!!demo)

    const handleClose = () => setOpen(false)

    const handleOpen = () => setOpen(true)

    return (
        <>
            <Navbar handleOpen={handleOpen}/>
            <Banner handleOpen={handleOpen}/>
            <Vector color="#BF80FF"/>
            <Marks/>
            <Video/>
            <Information/>
            <Business handleOpen={handleOpen}/>
            <Monitor handleOpen={handleOpen}/>
            <Content/>
            <RoutesInfo/>
            <AutoGestion handleOpen={handleOpen}/>
            <Web handleOpen={handleOpen}/>
            <Faculty handleOpen={handleOpen}/>
            <BannerLeader handleOpen={handleOpen}/>
            <Contact/>
            <Footer/>
            <Social/>
            <DialogForm handleClose={handleClose} open={open}/>
        </>
    )
}

export default AcropolisLab