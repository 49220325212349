import React from 'react';
import { StylesProvider } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function MaterialThemeProvider(props) {


    const theme = createTheme ({
        typography: {
            fontFamily: ['Montserrat'].join(','),
            fontSize: 16,
            button: {
                textTransform: 'none',
                fontWeight: 'bold'
            }
        },
        
        // palette: {
        //     primary: {

        //         main: '#BF80FF',
        //         // dark: will be calculated from palette.primary.main,
        //         contrastText: '#fff' //will be calculated to contrast with palette.primary.main
        //     },
        //     background: {
        //         default: '#f9f9f9 !important'
        //     },
        //     secondary: {
        //         // light: will be calculated from palette.primary.main,
        //         main: '#16151E'
        //         // dark: will be calculated from palette.primary.main,
        //         // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        //     },
        //     muted: {
        //         main: '#c4c4c4'
        //     },
        //     error: {
        //         // light: will be calculated from palette.primary.main,
        //         main: '#f8423f'
        //         // dark: will be calculated from palette.primary.main,
        //         // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        //     }
        // },
        palette: {
            primary: {

                main: '#591AB6',
                // dark: will be calculated from palette.primary.main,
                contrastText: '#fff' //will be calculated to contrast with palette.primary.main
            },
            purpleLight: {
                main: '#BF80FF'
            },
            purpleDarker: {
                main: '#13003E'
            },
            purpleDark: {
                main: '#2E0A6C'
            },
            purpleLighter: {
                main: '#D8B3FF'
            },
            background: {
                default: '#f9f9f9 !important'
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: '#16151E'
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            tertiary: {
                main: '#FFFFFF'
            },
            muted: {
                main: '#c4c4c4'
            },
            error: {
                // light: will be calculated from palette.primary.main,
                main: '#f8423f'
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            }
        },
        props: {
            // Name of the component ⚛️
            MuiButtonBase: {
                // The properties to apply
                disableRipple: false // No more ripple, on the whole application 💣!
            },

            // Set default elevation to 1 for popovers.
            MuiPopover: {
                elevation: 1
            }
        },
    });
    const { children } = props;

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StylesProvider>
    );
}
