import { LinearProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ProgressBar = (props) => {
  const { value } = props;

  return (
    <LinearProgressFake id="progress" variant="determinate" value={value} />
  );
};

export default ProgressBar;

const LinearProgressFake = styled(LinearProgress)`
  height: 8px !important;
  border-radius: 3px;
  background-color: #d9d9d9 !important;
  span {
    background-color: #2e0a6c !important;
  }
`;
