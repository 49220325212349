import React from 'react'
import styled from 'styled-components'
// import alab from '../../assets/images/alab-white.svg'
import alabLogoWhite from '../../assets/images/acropolislab-logo-white.svg'


import Image from './Image'

const AlabLogo = () => {
    return (
        <AlabWrapper href="/#inicio">
            <Image src={alabLogoWhite} width="80px" />
        </AlabWrapper>
    )
}

export default AlabLogo

const AlabWrapper = styled.a`
    width: 128px;
    height: 125px;
    background-color: #591AB6;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    cursor: pointer;
    @media (max-width: 768px){
        width: 90px;
        height: 100px;
        /* z-index: 9999; */
        img{
            width: 60px;
        }
    }
`