import React from "react";
import styled from "styled-components";
// import letters from '../../assets/images/letters.png'
import letters from "../../assets/images/acropolis-letters-purple-light-m.png";

import useCMS from "../../hooks/useCMS";
import DefaultButton from "../common/DefaultButton";
import DefaultText from "../common/DefaultText";
import Link from "../common/Link";

const Footer = () => {
  const { calendar, footer } = useCMS();

  return (
    <FooterWrapper>
      <Data>
        {footer &&
          footer.map((item) => (
            <Info>
              <DefaultText 
              color="#FFFFFF" 
              fontSize="18px" 
              fontWeight="700"
              lineHeight="22px"

              >
                {item.title}
              </DefaultText>
              {item.links.map((link) => (
                <Link
                  href={link.url}
                  target="_blank"
                  color="white"
                  fontSize="18px"
                  fontWeight="400"
                  lineHeight="22px"
                >
                  {link.name}
                </Link>
              ))}
            </Info>
          ))}
      </Data>
      <Card bg={letters}>
        <DefaultText color="#591AB6" fontSize="24px" fontWeight="700">
          Agenda tu consulta sin cargo
        </DefaultText>
        <DefaultText
          color="#616161"
          fontSize="18px"
          fontWeight="400"
          textAlign="start"
        >
          ¡Descubre cómo Acrópolis LAB puede ayudar a tu empresa!
        </DefaultText>
        <DefaultButton
          disabled={!calendar}
          onClick={() => window.open(calendar)}
          color="primary"
          variant="contained"
          fullWidth
        >
          Comercial
        </DefaultButton>
      </Card>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.section`
  height: auto;
  width: calc(100% - 200px);
  padding: 0 100px;
  background-color: #2e0a6c;
  height: 380px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 18px 0;
    width: 100%;
    gap: 16px;
    /* padding: 18px 0; */
  }
`;

const Data = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
`;

const Card = styled.div`
  min-height: 236px;
  padding: 0.5rem 2rem;
  background-color: #fff;
  border-radius: 20px;
  /* background-image: url(${(p) => p.bg}); */
  /* background-size: 200px; */
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: start;

  &&::before {
    content: "";
    background-image: url(${(p) => p.bg});
    background-size: 150px;
    background-repeat: repeat-x;
    background-position: center center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.059;
    z-index: 0;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
