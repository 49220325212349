import React from "react";
import styled from "styled-components";
// import DefaultSpan from "../common/DefaultSpan";
import DefaultText from "../common/DefaultText";
import Image from "../common/Image";
// import notebook from "../../assets/images/notebook2.png";
import laptopEstudiante from "../../assets/images/laptop-estudiante.png";

import AddIcon from "@mui/icons-material/Add";
import DefaultButton from "../common/DefaultButton";
import UnderlinedText from "../common/UnderlinedText";

const AutoGestion = (props) => {
  const { handleOpen } = props;

  return (
    <AutoGestionWrapper>
      <ImageContainer bgi={laptopEstudiante}>
        {/* <Image width={"95%"} src={laptopEstudiante} /> */}
      </ImageContainer>
      <Info>
        <DefaultText
          id="fontTitle"
          color="#13003E"
          fontWeight="700"
          fontSize="48px"
        >
          Cursado online
          {" "}
        <UnderlinedText
          textDecorationColor="#BF80FF"
          textDecorationStyle="solid"
          heigt="9px"
          color="#13003E"
        >
          100% autogestionable
        </UnderlinedText>{" "}



          {/* <DefaultSpan color="#B31D15">100% autogestionable</DefaultSpan> */}
        </DefaultText>
        <DefaultText color="#616161" fontWeight="400" fontSize="18px">
          Tus colaboradores realizarán su cursado a través de Acrópolis, la
          plataforma educativa de ADEN.
        </DefaultText>
        <List>
          <Item>
            <AddIcon />
            <DefaultText fontSize="18px">
              Personaliza la plataforma con tus colores y marca
            </DefaultText>
          </Item>
          <Item>
            <AddIcon />
            <DefaultText fontSize="18px">
              Cada colaborador tendrá acceso a su analítica personal de avance
            </DefaultText>
          </Item>
          <Item>
            <AddIcon />
            <DefaultText fontSize="18px">
              Certificados online al finalizar cada curso
            </DefaultText>
          </Item>
          <Item>
            <AddIcon />
            <DefaultText fontSize="18px">
              Acceso desde cualquier dispositivo
            </DefaultText>
          </Item>
          <Item>
            <AddIcon />
            <DefaultText fontSize="18px">Soporte en línea</DefaultText>
          </Item>
        </List>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="primary"
            variant="contained"
          >
            Empezar ahora
          </DefaultButton>
        </Buttons>
      </Info>
    </AutoGestionWrapper>
  );
};

export default AutoGestion;

const AutoGestionWrapper = styled.section`
  height: auto;
  width: calc(100% - 100px);
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "top bottom";
  gap: 1rem;
  margin: 4% 0;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "top   "
      "bottom";
    padding: 1rem;
    width: calc(100% - 2rem);
    justify-items: center;
    justify-content: center;
    align-items: center;
  }

  /* @media (max-width: 768px){
        grid-template-areas: "top   "
                             "bottom";  
        padding: 0 24px;
        width: calc(100% - 12px);
        flex-direction: column;
        text-align: center;
    } */
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 700px;
  grid-area: top;
  text-align: center;
  margin-right: 2rem;
  background-image: url(${(p) => p.bgi});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  object-fit: contain;
  @media (max-width: 1100px) {
    height: 300px;
    padding: 0;
  }
/* 
  img {
    width: 100%;
    padding: 2rem;
  }
  @media (max-width: 1100px) {
    img {
      width: 60%;
      padding: 0;
    }
  } */
`;

const Info = styled.div`
  grid-area: bottom;
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    width: 100%;
  }
  @media (max-width: 768px) {
    transform: scale(0.9);
    width: 100%;
  }
`;

const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
  @media (max-width: 768px) {
    text-align: start;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
  p,
  b {
    color: #616161;
  }
  svg {
    fill: #591AB6;
  }
`;

const Buttons = styled.div`
  width: 100%;
  margin-top: 18px;
`;
