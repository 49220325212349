import { Button } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const DefaultButton = (props) => {
  return (
    <ButtonFake {...props}/>
  )
}

export default DefaultButton

const ButtonFake = styled(Button)`
    border-radius: 3rem !important;
    height: ${p => p.height || "3.3rem"};
    width: ${p => p.width || "auto"};
    padding: 0 2rem !important;
    svg{
      fill: ${ p => p.color ? `${p.colorFill} !important`  : "#b31d15 !important"};
    }
`