import axios from "axios"

const ADEN_URL = 'https://www.aden.org/tpl/adenv4/custom/'
const ADEN_FORM = 'https://landings.aden.org'
const SIS_API = 'https://sisapi.aden.org/api'

export const getRegionsServices = async (code) => {
    try {
        const req = await axios.get(`${ADEN_URL}/load.php?countrycode=${code}&type=region`)
        return req.data
    } catch (error) {
        throw Error(error)
    }
}


export const createOpportunity = async (body) => {
    try {
        const req = await axios.post(`${ADEN_FORM}/aden_forms/contacto_mh/nuevo_contacto`, body)
        return req.data
    } catch (error) {
        throw Error(error)
    }
}

export async function sendFormData(body) {
    try {
        const response = await axios.post(`${SIS_API}/v1/crm/lead/create_contact_company_lead`, body)
        return response.data
    } catch (error) {
        return {
            error
        }
    }
}