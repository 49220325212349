import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { getRouteServiceBySlug } from "../services/cms"

const useRouteBySlugForm = () => {
    
    const { slug } = useParams();
    const [route, setRoute] = useState(null)

    useEffect(() => {
        if(!route ){
            getRoute();
        }
    }, [])

    const getRoute = async () => {
        try {
            const req = await getRouteServiceBySlug(slug)
            setRoute(req)
        } catch (error) {
            console.error(error);
        }
    }
    return { route };
}

export default useRouteBySlugForm;