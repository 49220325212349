import React from "react";
import styled from "styled-components";
import DefaultButton from "../common/DefaultButton";
// import DefaultSpan from "../common/DefaultSpan";
import DefaultText from "../common/DefaultText";
import { Carousel } from "3d-react-carousal";
import useFaculty from "../../hooks/useFaculty";
import UnderlinedText from "../common/UnderlinedText";

const Faculty = (props) => {
  const { handleOpen } = props;

  const { facultyCards } = useFaculty();

  const callback = (index) => {
    // console.log("callback",index);
  };

  if (!facultyCards) {
    return false;
  }
  return (
    <FacultyWrapper id="faculty">
      <Info>
        <Slider>
          <Carousel
            slides={facultyCards}
            autoplay={true}
            interval={7000}
            onSlideChange={callback}
          />
        </Slider>
      </Info>
      <Info>
        <DefaultText
          id="fontTitle2"
          color="#13003E"
          fontWeight="700"
          fontSize="3rem"
        >
          Faculty de prestigio{" "}
          <UnderlinedText
            textDecorationColor="#BF80FF"
            textDecorationStyle="solid"
            heigt="9px"
            color="#13003E"
          >
            internacional
          </UnderlinedText>{" "}
          {/* <DefaultSpan color="#B31D15">internacional</DefaultSpan> */}
        </DefaultText>
        <DefaultText
          color="#616161"
          fontWeight="400"
          fontSize="18px"
          lineHeight="25.2px"
        >
          Nuestros docentes te aportarán distintas perspectivas de gestión en el
          entorno de los negocios, creando un programa dinámico y desafiante.
        </DefaultText>
        <DefaultText color="#616161" fontWeight="400" fontSize="18px">
          El cuerpo docente de ADEN está formado por directivos de reconocidas
          empresas internacionales con amplia experiencia gerencial. Son
          expertos en su materia, académicos y autores de libros de consulta y
          publicaciones en medios de prestigio.
        </DefaultText>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="primary"
            variant="contained"
          >
            Empezar ahora
          </DefaultButton>
        </Buttons>
      </Info>
    </FacultyWrapper>
  );
};

export default Faculty;

const FacultyWrapper = styled.section`
  height: auto;
  width: calc(100% - 300px);
  padding: 5% 150px;
  display: flex;
  /* margin: 5% 0; */
  @media (max-width: 1000px) {
    width: calc(100% - 24px);
    padding: 12px;
    flex-direction: column;
    text-align: center;
    div {
      width: 100%;
      overflow-x: visible !important;
    }
  }
`;

const Info = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
  }
`;

const Buttons = styled.div`
  width: 100%;
  margin-top: 18px;
  margin-left: 18px;
`;

const Slider = styled.div`
  width: 90%;
  margin: 2rem 0;
  .fa {
    color: #591ab6;
  }
`;
