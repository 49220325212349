import React from "react";
import styled from "styled-components";
// import img from "../../assets/images/banner.png";
import shutterstockBanner from "../../assets/images/shutterstock_banner.svg";
import acropolislabHeroBg from "../../assets/images/acropolislab-hero-bg.png";

import DefaultText from "../common/DefaultText";
// import DefaultSpan from "../common/DefaultSpan";
import DefaultButton from "../common/DefaultButton";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UnderlinedText from "../common/UnderlinedText";

const Banner = (props) => {
  const { handleOpen } = props;

  return (
    <BannerWrapper id="inicio" bg={acropolislabHeroBg}>
      <InfoBanner>
        <DefaultText
          id="fontTitle"
          fontWeight="700"
          fontSize="3.5rem"
          color="#FFFFFF"
        >
          El ecosistema de aprendizaje para
          {" "}
          <UnderlinedText
            textDecorationColor="#2E0A6C"
            textDecorationStyle="solid"
            heigt="12px"
            color="#FFFFFF"
            fontWeight="700"
            fontSize="3.5rem"
          >
            tu empresa
          </UnderlinedText>
          {" "}
        </DefaultText>
        <DefaultText fontWeight="400" fontSize="1.125rem" color="#FFFFFF">
          Una solución integral para tu empresa a través de una plataforma de
          aprendizaje única, con contenido desarrollado por expertos y acciones
          que apuntan al crecimiento profesional.
        </DefaultText>
        <Buttons>
          <DefaultButton
            onClick={handleOpen}
            color="purpleLighter"
            variant="contained"
          >
            Empezar ahora
          </DefaultButton>
          {/* <DefaultButton color="primary" endIcon={<ArrowForwardIcon color="primary"/>}>Solicitar más información</DefaultButton> */}
        </Buttons>
      </InfoBanner>
      <ImageWrapper bg={shutterstockBanner} />
    </BannerWrapper>
  );
};

export default Banner;

const BannerWrapper = styled.div`
  height: calc(90vh - 101px);
  padding: 0;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  background-image: url(${(p) => p.bg});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1440px) {
    grid-template-columns: 0.8fr 1fr;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    text-align: start;
  }
`;

const InfoBanner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
  padding: 1.5rem;
  overflow: hidden;
  gap: 1rem;

  /* p{
        width: 100%;
        overflow: none;
        padding: 0;
        flex-wrap: wrap;

    } */

  @media (max-width: 1440px) {
    height: auto;
    width: auto;
    margin-top: 4rem;
    #title {
      transform: scale(0.8);
    }
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${(p) => p.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  object-fit: contain;
  @media (max-width: 768px) {
    /* display: none; */
    height: 30vh;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 12px;
  width: calc(100% - 36px);
  padding: 0 18px;
  margin-top: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;
