import axios from "axios"

const CMS_URL = 'https://cms.aden.org/api'

export const getRoutesService = async () => {
    try {
        const req = await axios.get(`${CMS_URL}/rutas-formacion?populate=*`)
        return req.data?.data
    } catch (error) {
        throw Error(error)
    }
}

export const getRouteServiceById = async (id) => {
    try {
        const req = await axios.get(`${CMS_URL}/rutas-formacion/${id}?populate=deep`)
        return req.data?.data
    } catch (error) {
        throw Error(error)
    }
}

export const getRouteServiceBySlug = async (slug) => {
    try {
        const req = await axios.get(`${CMS_URL}/rutas-formacion?filters[slug][$eq]=${slug}&populate=*`)
        // console.log('req-getRouteServiceBySlug', req)
        return req.data?.data[0]
    } catch (error) {
        throw Error(error)
    }
}

export const getVideoService = async () => {
    try {
        const req = await axios.get(`${CMS_URL}/home-acropolislab?populate=video_link`)
        return req.data?.data
    } catch (error) {
        throw Error(error)
    }
}

export const getTutorsService = async () => {
    try {
        const req = await axios.get(`${CMS_URL}/home-acropolislab?populate=deep`)
        return req.data?.data
    } catch (error) {
        throw Error(error)
    }
}

export const getDataService = async () => {
    try {
        const req = await axios.get(`${CMS_URL}/home-acropolislab?populate=deep`)
        return req.data?.data
    } catch (error) {
        throw Error(error)
    }
}