import React from 'react'
import styled from 'styled-components'
import DefaultText from '../common/DefaultText'

const Content = () => {
    return (
        <ContentWrapper>
            <DefaultText color="#fff" fontSize="3rem" fontWeight="700">Contenido <br/> empresa</DefaultText>
            <DefaultText color="#BF80FF" fontSize="3rem" fontWeight="700">+</DefaultText>
            <DefaultText color="#fff" fontSize="3rem" fontWeight="700">Contenido <br/> ADEN</DefaultText>
        </ContentWrapper>
    )
}

export default Content

const ContentWrapper = styled.section`
    height: 260px;
    background-color: #13003E;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 2rem 0;

    @media (max-width: 768px){
        height: auto;
        flex-direction: column;
        text-align: center;
        padding: 0.5rem 0;
        p, span {
            transform: scale(.7);
        }
    }
`