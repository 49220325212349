import React from "react";
import styled from "styled-components";
import DefaultText from "../common/DefaultText";
import SimpleLoading from "../common/SimpleLoading";

const CoursesInfo = (props) => {
  const { courses, attributes } = props;
  const URL_CMS = "https://cms.aden.org";

  if (!courses) {
    return <SimpleLoading />;
  }
  return (
    <CoursesWrapper id="courses">
      <DefaultText color="#591AB6" fontSize="1.5rem">
        CURSOS ADEN
      </DefaultText>
      <DefaultText
        textAlign="center"
        color="#13003E"
        fontSize="2.5rem"
        fontWeight="700"
      >
        Potencia las habilidades de tu equipo
      </DefaultText>
      <Grid>
        {courses?.map((c) => (
          <CardCourse>
            <div>
              <Image
                bg={
                  c?.attributes?.cover?.data?.attributes?.url
                    ? `${URL_CMS}${c.attributes.cover.data.attributes.url}`
                    : `${URL_CMS}${attributes?.cover_image?.data?.attributes?.url}`
                }
              />
            </div>
            <div>
              {/* <Text color="#591AB6;" fontSize="0.625rem" fontWeight="500">
                            {
                            // c?.tags[0]?.name 
                            //     ? c.tags[0].name.toUpperCase() 
                            //     : 
                                attributes?.repo_tags[0].toUpperCase() 
                            }
                        </Text> */}
              <Text color="#13003E" fontSize="1rem" fontWeight="700">
                {c?.attributes?.title}
              </Text>
              {c?.attributes?.description ? (
                <Description>
                  <Text color="#616161" fontSize="0.875rem" fontWeight="400">
                    {c?.attributes.description}
                  </Text>
                </Description>
              ) : null}

              {c?.attributes?.instructor ? (
                <Description>
                  <Text color="#616161" fontSize="0.875rem" fontWeight="400">
                    Por: {c?.attributes.instructor}
                  </Text>
                </Description>
              ) : null}
            </div>
          </CardCourse>
        ))}
      </Grid>
    </CoursesWrapper>
  );
};

export default CoursesInfo;

const CoursesWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 5% 0;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Grid = styled.div`
  margin-top: 24px;
  width: 90%;
  padding: 0.5rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: center;
  justify-items: center;
  text-align: start;
  gap: 2rem;
`;

const CardCourse = styled.div`
  width: 250px;
  height: calc(310px - 16px);
  margin: 0;
  display: grid;
  grid-template-rows: 100px minmax(10px, 194px);
  justify-content: normal;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  gap: 4px;
  cursor: pointer;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: pre-line;
`;

const Text = styled.p`
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => p.color};
  text-overflow: ellipsis;
  padding: 10px 16px;
`;

const Image = styled.div`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  padding: 0;
  background: url(${(p) => p.bg}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Description = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 95px;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(200, 200, 200);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
  }
`;
