import React from 'react';
import MaterialThemeProvider from "./assets/theme/Material";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import RutaDeFormacion from './pages/RutaDeFormacion';
import AcropolisLab from './pages/AcropolisLab';


function App() {


  //RUTAS_react-router-dom V6.4
  const router = createBrowserRouter([
    {
      path: "/",
      element:  <AcropolisLab/>,
    },
    {
      path: "ruta/:slug",
      element: <RutaDeFormacion/>,
    },
  ]);


  return (
      <MaterialThemeProvider>
        <RouterProvider router={router} />
      </MaterialThemeProvider>
  );
}

export default App;
