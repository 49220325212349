import React, { useState } from "react";
import styled from "styled-components";
import { createOpportunity, sendFormData } from "../../../../services/aden";
import ChatUser from "../../ChatUser";
import FormCases from "./FormCases";
import Image from "../../Image";
import ProgressBar from "../../ProgressBar";
import loading from "../../../../assets/images/loading.gif";
import DefaultText from "../../DefaultText";
import chat from "../../../../assets/images/chat.png";
import DefaultButton from "../../DefaultButton";

const FormStep = (props) => {
  const { handleClose } = props;

  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [form, setForm] = useState(null);
  const [page, setPage] = useState(null);

  const handleStep = (step, allSteps) => {
    setStep(step);
    // setCountStep(allSteps)
    handleProgress(step + 1, allSteps);
  };

  const handleProgress = (page, total) => {
    const progress = (page * 100) / total;
    setProgress(progress);
  };

  const handleForm = (form) => {
    setForm(form);
  };

  const handleReset = () => {
    setPage(null);
  };

  const submitData = async (body) => {
    const data = new FormData();
    data.append("nombre", body.name);
    data.append("apellido", body.lastName);
    data.append("pais", body.country);
    data.append("ciudad", body.region);
    data.append("telefono", body.phone);
    data.append("particular", body.email);
    data.append("sede", "E-Learning");
    data.append("programa", "Acropolis Lab");
    data.append("idprodmkt", "ACrop1");
    data.append("utm_campaign", "Home-ALab");
    data.append("idevento", "0");
    data.append("redirect_page", "https://www.acropolislab.com/");
    data.append("edad", "");
    data.append("fecha", "");
    data.append("empresa", body.business);
    data.append("id_cargo_crm", body.position);
    data.append("area_laboral", "otra");
    data.append("estudio", "1");
    data.append("lang", "es");
    data.append("utm_source", "Web");
    data.append("utm_medium", "Organic");

    setPage("loading");
    try {
      await sendFormData(data);
      setPage("success");
    } catch (error) {
      setPage("error");
    }
  };

  if (page === "loading") {
    return (
      <FormWrapper>
        <FlexWrapper>
          <ImageWrapper>
            <Image width={"120px"} src={loading} />
          </ImageWrapper>
          <DefaultText fontWeight="700" fontSize="18px" color="#2E0A6C">
            CARGANDO...
          </DefaultText>
        </FlexWrapper>
      </FormWrapper>
    );
  }

  if (page === "success") {
    return (
      <FormWrapper>
        <FlexWrapper>
          <ImageContent>
            <Image src={chat} />
          </ImageContent>
          <DefaultText
            textAlign="center"
            fontWeight="900"
            fontSize="32px"
            color="#2E0A6C"
          >
            ¡Gracias por registrar tus datos!
          </DefaultText>
          <DefaultText
            textAlign="center"
            fontWeight="400"
            fontSize="18px"
            color="#787878"
          >
            En breve un asesor se podrá en contacto contigo para brindarte más
            información
          </DefaultText>
          <DefaultButton
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            Entendido
          </DefaultButton>
        </FlexWrapper>
      </FormWrapper>
    );
  }

  if (page === "error") {
    return (
      <FormWrapper>
        <FlexWrapper>
          <ImageContent>
            <Image src={chat} />
          </ImageContent>
          <DefaultText
            textAlign="center"
            fontWeight="900"
            fontSize="32px"
            color="#3f3d56"
          >
            ¡Ocurrió un problema!
          </DefaultText>
          <DefaultText
            textAlign="center"
            fontWeight="400"
            fontSize="18px"
            color="#787878"
          >
            ¡Por favor! Volver a intentar
          </DefaultText>
          <DefaultButton
            onClick={handleReset}
            variant="contained"
            color="primary"
          >
            Reintenter
          </DefaultButton>
        </FlexWrapper>
      </FormWrapper>
    );
  }

  return (
    <FormWrapper>
      <ProgressBar value={progress} />
      <ChatUser step={step} form={form} />
      <FormCases
        handleForm={handleForm}
        handleStep={handleStep}
        step={step}
        submitData={submitData}
      />
    </FormWrapper>
  );
};

export default FormStep;

const FormWrapper = styled.div`
  padding: 52px;
  display: flex;
  gap: 18px;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 50%;
  }
`;

const FlexWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const ImageContent = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: contain;
`;
