import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const  SelectField = (props) => {

    const { value, onChange, items = [], name, required, label } = props

    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    value={value}
                    onChange={(e) => onChange({ target: {
                        name, value: e.target.value
                    }})}
                    name={name}
                    required={required}
                >
                    {items?.map(item => <MenuItem value={item?.code || item?.id}>{item?.name}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    );
}

export default SelectField