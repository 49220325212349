import { useState, useEffect } from "react";
import useCountries from "./useCountries";
import { sendFormData } from "../services/aden";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";

const useForm = () => {
  const { countries, regions, handleRegion } = useCountries();

  const queryParams = new URLSearchParams(useLocation().search);

  const utm_source = queryParams.get("utm_source");
  const utm_medium = queryParams.get("utm_medium");
  const utm_campaign = queryParams.get("utm_campaign");

  // REACT
  const [form, setForm] = useState({});
  const [valid, setValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openSkackbar, setOpenSnackbar] = useState(false);
  const [skackbarMessage, setSkackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // EMAIL VALIDATION
  const REGEX_EMAIL =
    /(^[-\w.%+]{1,64})@((?:[gmail|yahoo|hotmail]{2,16})\.){1,125}[A-Z]{2,63}$/gi;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((state) => ({ ...state, [name]: value }));
    if (name === "country") {
      handleRegion(value);
      validateForm({ ...form, region: null });
      return;
    }
    validateForm({ ...form, [name]: value });
  };

  const validateForm = (form) => {
    !!form?.name &&
    !!form?.lastName &&
    !!form?.country &&
    !!form?.phone &&
    !!form?.email &&
    !!form?.business &&
    !!form?.employees
      ? setValid(true)
      : setValid(false);
  };

  const submitData = async (body) => {
    const data = new FormData();
    data.append("nombre", body.name);
    data.append("apellido", body.lastName);
    data.append("pais", body.country);
    data.append("ciudad", "");
    data.append("telefono", body.phone);
    data.append("particular", body.email);
    data.append("sede", "E-Learning");
    data.append("programa", "Acropolis Lab");
    data.append("idprodmkt", "ACrop1");
    data.append("utm_campaign", utm_campaign || "");
    data.append("idevento", "0");
    data.append("redirect_page", "https://www.acropolislab.com/");
    data.append("edad", "");
    data.append("fecha", "");
    data.append("empresa", body.business);
    data.append("id_cargo_crm", body.position || "");
    data.append("area_laboral", "otra");
    data.append("estudio  ", "1");
    data.append("lang", "es");
    data.append("utm_source", utm_source || "");
    data.append("utm_medium", utm_medium || "");
    // data.append("consulta", body.size);

    setIsLoading(true);
    try {
      await sendFormData(data);
      setIsSubmitted(true);
      setIsLoading(false);
    } catch (error) {
      setOpenSnackbar(true);
      setSkackbarMessage("No se pudo enviar el formulario!");
    }
  };

  const formSubmitted = async () => {
    const formSubmitted = await localStorage.getItem("required");
    handleIsSubmitted(formSubmitted);
  };

  const handleIsSubmitted = (formSubmitted) => {
    if (!formSubmitted) {
      localStorage.setItem("required", true);
      setIsSubmitted(true);
    }
  };

  const handleSubmit = () => {
    if (REGEX_EMAIL.test(form?.email)) {
      setOpenSnackbar(true);
      setSkackbarMessage("Debe ingresar su e-mail corporativo!");
      return;
    }

    const body = {
      ...form,
    };

    submitData(body);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return {
    form,
    valid,
    handleChange,
    countries,
    handleSubmit,
    openSkackbar,
    handleCloseSnackbar,
    skackbarMessage,
    isLoading,
    isSubmitted,
  };
};

export default useForm;
